import getConstant from './constants';

// Dynamically register all of the available locales if they have a timeago file


  
  
    
    import de from '../locale/de/timeago';
  

  
  
    
    import en_GB from '../locale/en_GB/timeago';
  

  
  
    
    import en_US from '../locale/en_US/timeago';
  

  
  
    
    import es from '../locale/es/timeago';
  

  
  
    // Issue warnings about the missing Timeago file for the locale
    
    console.log('No Timeago registered for fr');
  

  
  
    // Issue warnings about the missing Timeago file for the locale
    
    console.log('No Timeago registered for ja');
  

  
  
    
    import pt_BR from '../locale/pt_BR/timeago';
  


const TimeagoFactory = (() => {
  // Register all of the locales with Timeago
  
    timeago.register('de', de);
  
    timeago.register('en_GB', en_GB);
  
    timeago.register('en_US', en_US);
  
    timeago.register('es', es);
  
    timeago.register('pt_BR', pt_BR);
  

  const defaultLocale = 'en_GB'

  const convertTime = (currentLocale, el) => {
    return timeago.format($(el).attr('datetime'), `${currentLocale}`);
  };

  return {
    render: (el) => {
      const currentLocale = getConstant('CURRENT_LOCALE') || defaultLocale;

      if (el.length > 1) {
        // If we were passed an array of JQuery elements then handle each one
        $(el).each((idx, e) => {
          $(e).text(convertTime(currentLocale, e));
        });
      } else {
        $(el).text(convertTime(currentLocale, el));
      }
    },
  };
})();

export default TimeagoFactory;
